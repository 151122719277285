export const COMMON = {
  NOT_SPECIFIED: 'Not Specified',
  NOT_SET: 'Not set',
  GENERAL: {
    APP_NAME: 'Star'
  },
  LINKS: {
    WIKI: {
      CV_TERMS: "https://wiki.star.global/display/STAR/CV+creating+and+updating+guideline"
    }
  }
};
