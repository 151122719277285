import React, {FunctionComponent, useState} from 'react';

import './text-column.scss';
import {useAsyncDebounce} from "react-table";

interface TextColumnFilterProps {
  column: {
    filterValue: string;
    setFilter: Function;
  };
}

export const TextColumnFilter: FunctionComponent<TextColumnFilterProps> = (props: TextColumnFilterProps) => {
  const column = props.column;
  const {filterValue, setFilter} = {...column};
  const [value, setValue] = useState<string>(filterValue);
  const applyValue = useAsyncDebounce(value => {
    setFilter(value);
  }, 50);

  return (
    <input
      className="input-filter-input"
      value={value || ''}
      onChange={event => {
        setValue(event.target.value || '');
        applyValue(event.target.value || undefined)
      }}
      placeholder="Start typing"
    />
  )
}
