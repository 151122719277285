import React, { FunctionComponent, useEffect, useMemo, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { useFilters, useGlobalFilter, useSortBy, useTable } from 'react-table';
import { FontAwesomeIcon as FA } from '@fortawesome/react-fontawesome';

import client from '../../../services/http/StaffClient';
import wrClient from './../../../services/http/WorkRecordClient';
import EmployeeTableHelper from '../../../services/data/EmployeeTableHelper';

import StaffMapper from '../../../mappers/response/StaffMapper';

import store from '../../../store/store';
import { setStaff } from '../../../store/staff/actions';
import { loaderActions } from "../../../store/loader/actions";

// Components
import { betweenDatesFilterFn, fuzzyTextFilterFn, multipleFilterFn } from '../../Tables/TableFunctions';
import { TextColumnFilter, FilterTrigger, FilterWrapper } from '../../Tables/Filters';
import { EmployeeTableShortInfo } from './EmployeeTableShortInfo';
import { EmployeeTableSettings } from './EmployeeTableSettings';
import { EmployeeTableFilter } from './EmployeeTableFilter';
import { WRPreview } from '../../WR/WRPreview/WRPreview';
import { EMPLOYMENT_TYPE_ALL, EmploymentTypes } from '../../Forms/Standalone/EmployeeEmploymentType';

import { ALL_EMPLOYEE_FILTERS, TABLE_NAMES } from '../../../constants';

import './employee-table.scss';

interface EmployeeTableProps {
  columns: Array<any>;
}

export const EmployeeTable: FunctionComponent<EmployeeTableProps> = (props: EmployeeTableProps) => {
  const columns = props.columns;
  const search = useLocation().search;
  const searchParams = new URLSearchParams(search);
  const dateParam = searchParams.size && searchParams.get('date') ? new Date(searchParams.get('date') as string) : new Date();
  const [asOfDate, setAsOfDate] = useState<Date>(dateParam);
  const [dateRange, setDateRange] = useState<Array<string>>(['current']);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const [separatedData, setSeparatedData] = useState<any>({
    all: [],
    employee: [],
    contractor: []
  });
  const [data, setData] = useState<Array<any>>([]);
  const [filterHeight, setFilterHeight] = useState<number>(0);

  const [filterEmployment, setFilterEmployment] = useState<EmploymentTypes>(EMPLOYMENT_TYPE_ALL);

  const filterTypes = useMemo(() => ({
    text: fuzzyTextFilterFn,
    multiple: multipleFilterFn,
    betweenDates: betweenDatesFilterFn,
  }), []);

  const defaultColumn: any = useMemo(() => ({
    Filter: TextColumnFilter,
  }), []);

  const hiddenColumns: Array<string> = [];
  const columnsMap: Array<string> = [];
  columns.map(column => {
    columnsMap[column.accessor] = column.Header;
    if (!column.isVisible) {
      hiddenColumns.push(column.accessor);
    }
  });
  
  const options = {
    columns,
    data,
    defaultColumn,
    filterTypes,
    initialState: {
      hiddenColumns: hiddenColumns
    }
  };
  
  // @ts-ignore
  const { getTableProps, getTableBodyProps, headerGroups, prepareRow, rows, allColumns, state, setFilter, setAllFilters } = useTable(
    options,
    useFilters,
    useGlobalFilter,
    useSortBy
  );
  
  const getCountryMap = () => {
    let locations: any = [];
    data.forEach((value: any) => {
      locations.push(value.location);
    });
    locations = Array.from(new Set(locations));
    let map: any = [];
    locations.forEach((value: string) => {
      let country = value.substr(value.indexOf(',') + 2, value.length);
      if (Object.keys(map).indexOf(country) === -1) {
        map[country] = [];
      }
      map[country].push(value);
    });
    return map;
  }

  //@ts-ignore
  const filters = state.filters;
  if (searchParams.size && data?.length) {
    const systemParams = Object.keys(ALL_EMPLOYEE_FILTERS);
    const countryList = getCountryMap();
    let filterList: any = [];
    searchParams.forEach((value: string, key: string) => {
      if (systemParams.indexOf(key) !== -1 && value !== null && value !== undefined) {
        let filterValue = (key === 'location') ? countryList[value] : [value];
        let filterKey = ALL_EMPLOYEE_FILTERS[key as keyof typeof ALL_EMPLOYEE_FILTERS];
        filterList.push({
          id: filterKey,
          value: filterValue
        });
      }
    });
    if (!filters.length && filterList.length) {
      setAllFilters(filterList);
    }
  }
  
  const filterProps = { columnsMap, filters, setFilter, setAllFilters, setFilterHeight };
  
  const getSeparatedData = (list: Array<any>) => {
    const employmentTypeContractor = ['External Contractor', 'Internal Contractor', 'Vendor'];
    let all: Array<any> = [];
    let employee: Array<any> = [];
    let contractor: Array<any> = [];
    for (let i = 0; i < list.length; i++) {
      if (list[i].employment === 'Employee') {
        employee.push(list[i]);
      } else if (employmentTypeContractor.includes(list[i].employment)) {
        contractor.push(list[i]);
      }
      all.push(list[i]);
    }
    return { all, employee, contractor };
  }

  const getData = (date: Date = new Date(), dataRange: Array<string> = ['current']) => {
    if (date) {
      store.dispatch(loaderActions.showLoader());
      setIsLoading(true);
      wrClient.getEmployeeList(date, dataRange).then((data) => {
        const converted = EmployeeTableHelper.convertListToData(data);
        const separatedData = getSeparatedData(converted);
        setSeparatedData(separatedData);
        setData(separatedData[filterEmployment]);
        setIsLoading(false);
        store.dispatch(loaderActions.hideLoader());
      });
    }
  }

  useEffect(() => {
    getData(asOfDate, dateRange);
  }, [asOfDate, dateRange]);

  useEffect(() => {
    if (separatedData) {
      setData(separatedData[filterEmployment]);
    }
  }, [filterEmployment, separatedData]);

  const [selectedWRId, setSelectedWRId] = useState<number | null>(null);
  const [showWRPreview, setShowWRPreview] = useState<boolean>(false);

  const showPreviewWR = (event: React.MouseEvent<HTMLLIElement>): void => {
    const idWr = event.currentTarget.getAttribute('data-wr-id');
    const idStaff = event.currentTarget.getAttribute('data-staff-id');

    if (idStaff) {
      client.get(parseInt(idStaff)).then((data: any) => {
        const staff = StaffMapper.creatStaffFromStaffResponse(data);
        store.dispatch(setStaff(staff));
        if (idWr) {
          setSelectedWRId(parseInt(idWr));
        } else if (staff.activeWRs.length) {
          setSelectedWRId(staff.activeWRs[staff.activeWRs.length - 1].id);
        } else {
          setSelectedWRId(staff.inactiveWRs[0].id);
        }
      });
    }
  };

  const tableWrapperStyles = {
    minHeight: `calc(100vh - 192px - ${filterHeight}px)`,
    height: `calc(100vh - 247px - ${filterHeight}px)`
  };

  return (
    <div className="employee-table-wrapper">
      <EmployeeTableShortInfo
        setFilterEmployment={ setFilterEmployment }
        list={ separatedData }
        asOfDate={ asOfDate }
        setAsOfDate={ setAsOfDate }
        dateRange={ dateRange }
        setDateRange={ setDateRange }
      />
      <div className="employee-table-header">
        <div className="employee-table-header__title">{ rows.length } records found</div>
        <EmployeeTableSettings
          columns={ allColumns }
          columnsTable={ columns }
          data={ rows }
          asOfDate={ asOfDate }
          setIsLoadingShared={ setIsLoading }
          csvDataFilePrefix='All'
          tableName={ TABLE_NAMES.ALL }
        />
      </div>
      <EmployeeTableFilter { ...filterProps } />
      <div className="table-wrapper" style={ tableWrapperStyles }>
        <table className="table" { ...getTableProps() }>
          <thead>
          {
            headerGroups.map((headerGroup: any) => (
              <tr { ...headerGroup.getHeaderGroupProps() }>
                {
                  headerGroup.headers.map((column: any) => (
                    <th { ...column.getHeaderProps() } style={{ width: `${column.width}px` }}>
                      {
                        column.sortable
                          ? (
                            <>
                              <span { ...column.getHeaderProps(column.getSortByToggleProps()) } className="table-sort">
                                { column.render('Header') }
                                {' '}
                                {
                                  column.isSorted
                                    ? column.isSortedDesc
                                    ? <FA icon={['fas', 'long-arrow-alt-down']} />
                                    : <FA icon={['fas', 'long-arrow-alt-up']} />
                                    : <FA icon={['fas', 'arrows-alt-v']} />
                                }
                              </span>
                            </>
                          )
                          : <>{ column.render('Header') }</>
                      }
                    {
                      column.canFilter && column.Filter
                        ? (
                          <span className="table-filter">
                            <FilterTrigger column={ column }>
                              <FA icon={ ['fas', 'filter'] } />
                            </FilterTrigger>
                            <FilterWrapper column={ column }>
                              { column.render('Filter') }
                            </FilterWrapper>
                          </span>
                        )
                      : <></>
                    }
                  </th>
                ))}
              </tr>
            ))
          }
          </thead>
          <tbody { ...getTableBodyProps() }>
          {
            rows.map((row: any, i: number) => {
                prepareRow(row);
                if (i === 0) {
                  row.stained = false;
                } else {
                  const prev: any = rows[i - 1];
                  row.stained = prev.original.staff_id === row.original.staff_id ? prev.stained : !prev.stained;
                }
                return (
                  <tr className={ row.stained ? 'row-blue' : 'row-white' } { ...row.getRowProps() } data-wr-id={ row.original.id } data-staff-id={ row.original.staff_id } onClick={ showPreviewWR }>
                    {
                      row.cells.map((cell: any) => {
                        return (<td { ...cell.getCellProps() }>{ cell.render('Cell') }</td>)
                      })
                    }
                  </tr>
                )
              }
            )
          }
          </tbody>
        </table>
        <WRPreview
          selectedWRId={ selectedWRId }
          setSelectedWRId={ setSelectedWRId }
          showWRPreview={ showWRPreview }
          setShowWRPreview={ setShowWRPreview }
        />
      </div>
    </div>
  );
}
