import React, { Fragment, useContext, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

import dictionary from '../../../services/dictionary/DictionaryStore';
import client from '../../../services/http/EducationClient';

import mapper from '../../../mappers/response/EducationMapper';

import { EducationItemResponse } from '../../../interfaces/http/education/EducationItemResponse';
import { EducationItem as EducationItemInterface } from '../../../interfaces/EducationItem';

import { EducationItem } from '../EducationItem/EducationItem';

import { EducationListContext } from '../../WR/WREducation/WREducation';

interface EducationListParams {
  staffId: string;
  wrId: string;
}

export const EducationList: React.FC = () => {
  const params = useParams<EducationListParams>();
  const cParams = {
    staffId: Number(params.staffId),
    wrId: Number(params.wrId)
  };

  const [isLoaded, setIsLoaded] = useState(false);
  const [items, setItems] = useState<Array<EducationItemInterface>>([]);
  const [degrees, setDegrees] = useState<Array<any>>([]);

  const context = useContext(EducationListContext);
  context.updateList = () => {setIsLoaded(false);}

  useEffect(() => {
    if (!isLoaded) {
      client.getEducationItems(cParams.staffId).then((data: any) => {
        data = data || [];
        const items: Array<EducationItemInterface> = [];
        (data as Array<EducationItemResponse>).forEach((item: EducationItemResponse) => {
          items.push(mapper.creatEducationItemFromResponse(item));
        })
        setItems(items);
      });
      dictionary.getDegrees().then((degrees: any) => {
        setDegrees(degrees);
      })
      setIsLoaded(true);
    }
  }, [isLoaded]);

  if (isLoaded) {
    return <div className="education-list">
      {
        (items.length)
          ? items.map((item: EducationItemInterface) =>
            <Fragment key={item.id}>
              <EducationItem educationItem={ item } degrees={ degrees } wrId={ cParams.wrId } />
              <hr/>
            </Fragment>
          )
          : <span>There are not education items</span>
      }
    </div>;
  }
  return <span>Education items are being loaded</span>;
}
