import {WorkRecord as WorkRecordInterface} from '../../interfaces/WorkRecord';
import {Staff as StaffInterface} from '../../interfaces/Staff';
import {Skill} from '../../interfaces/entity/Skill';
import diffService from './DiffService';
import staffHelper from './StaffHelper';
import requestService from './RequestService';
import DateHelper from '../../utils/date/DateHelper';
import client, {WORK_RECORD} from '../http/RequestClient';
import wrClient from '../http/WorkRecordClient';
import AddWorkRecordForm from '../../models/forms/AddWorkRecordForm';
import PositionDescriptionForm from '../../models/forms/PositionDescrioptionForm';
import {TrialStatusStateInterface} from "../../components/WR/Forms/TrialStatus/TrialStatusState";
import {TRIAL_STATUSES} from "../../constants/WORK_RECORD";

class WorkRecordHelper {

  public filter(workRecords: Array<WorkRecordInterface>, statuses: Array<string>): Array<WorkRecordInterface> {
    return workRecords.filter((wr: WorkRecordInterface) => {
      return (statuses.indexOf(wr.status) !== -1);
    })
  }

  public sort(workRecords: Array<WorkRecordInterface>): Array<WorkRecordInterface> {
    return workRecords.sort((wr1: WorkRecordInterface, wr2: WorkRecordInterface) => {
      if (wr1.endDate === null && wr2.endDate === null && wr1.startDate && wr2.startDate) {
        return (wr1.startDate.getTime() > wr2.startDate.getTime()) ? 1 : -1;
      } else if (wr1.endDate && wr2.endDate) {
        return (wr1.endDate.getTime() > wr2.endDate.getTime()) ? -1 : 1;
      } else if (wr1.endDate === null && wr2.endDate) {
        return -1;
      } else {
        return 1;
      }
    })
  }

  public validate(workRecord: WorkRecordInterface, formValues: any) {
    const body = this.prepareWRValidationBody(workRecord, formValues);
    return wrClient.validate(body);
  }

  public create(workRecord: WorkRecordInterface, formValues: any) {
    const body = this.prepareNewWRBody(workRecord, formValues);
    return wrClient.create(body);
  }

  public createWithStaff(staff: StaffInterface, workRecord: WorkRecordInterface, formValues: any) {
    const body: any = this.prepareNewWRBody(workRecord, formValues);
    body.staff = staffHelper.prepareNewStaffBody(staff, formValues);
    return wrClient.create(body);
  }

  public update(workRecord: WorkRecordInterface, formValues: any) {
    const body = this.prepareWRBody(workRecord, formValues);
    return client.update(workRecord.id, WORK_RECORD, body);
  }

  public activate(workRecord: WorkRecordInterface, formValues: any) {
    const startDate = (formValues.startDate) ? formValues.startDate : new Date();
    const body = {
      start_date: DateHelper.getFormatted2Date(startDate)
    }
    return wrClient.activate(workRecord.id, body);
  }

  public close(workRecord: WorkRecordInterface, formValues: any) {
    const body = this.prepareWRBody(workRecord, formValues);
    return wrClient.close(workRecord.id, body);
  }

  private prepareWRValidationBody(workRecord: WorkRecordInterface, formValues: any) {
    let values = formValues as AddWorkRecordForm;
    const applyChanges = DateHelper.getFormattedDate(values.applyChanges);
    let diff = diffService.getAttributesDiff(workRecord.attributes, values, 'wr', true);
    diff.forEach((value: any) => {
      value.start_date = applyChanges;
    });
    diff = requestService.upgradeForUpdate(diff);

    let body: any = {
      attributes: diff
    }

    return this.finalizeBodyNew(workRecord, formValues, body);
  }

  private prepareNewWRBody(workRecord: WorkRecordInterface, formValues: any) {
    let values = formValues as AddWorkRecordForm;
    const startDateAttr = DateHelper.getFormatted2Date(values.startDate!);
    let diff = diffService.getAttributesDiff(workRecord.attributes, values, 'wr', true);
    diff = diffService.addSkills(diff, formValues);
    diff.forEach((value: any) => {
      value.start_date = startDateAttr;
    });
    diff = requestService.upgradeForUpdate(diff);

    let body: any = {
      attributes: diff,
      staff: {id: workRecord.staffId}
    }
    return this.finalizeBodyNew(workRecord, formValues, body);
  }

  private prepareWRBody(workRecord: WorkRecordInterface, formValues: any) {
    let values = formValues as PositionDescriptionForm;
    const applyChanges = DateHelper.getFormatted2Date(values.applyChanges);
    let diff = diffService.getAttributesDiff(workRecord.attributes, values, 'wr');
    diff.forEach((value: any) => {
      value.apply_from = applyChanges;
    });
    diff = requestService.upgradeForUpdate(diff);

    let body: any = {};
    body.attributes = diff;

    return this.finalizeBody(workRecord, formValues, body);
  }

  private finalizeBody(workRecord: WorkRecordInterface, formValues: any, body: any) {
    const workRecordDiff = diffService.getWRPropertyDiff(workRecord, formValues);
    Object.keys(workRecordDiff).forEach((key) => {
      body[key] = workRecordDiff[key];
    });

    return body;
  }

  private finalizeBodyNew(workRecord: WorkRecordInterface, formValues: any, body: any) {
    const workRecordProperties = this.buildWorkRecordProperty(workRecord, formValues);
    Object.keys(workRecordProperties).forEach((key) => {
      body[key] = workRecordProperties[key];
    });

    return body;
  }

  public updateTurnover(workRecord: WorkRecordInterface, turnover: string) {
    let value: any = {
      new_value: turnover,
      attribute: 'turnover',
      apply_from: DateHelper.getFormatted2Date(new Date())
    };
    if (typeof workRecord.turnover.valueId !== 'undefined') {
      value.id = workRecord.turnover.valueId;
    }

    return client.update(workRecord.id, WORK_RECORD, {attributes: requestService.upgradeForUpdate([value])});
  }

  public updateReasonToLeave(workRecord: WorkRecordInterface, reasonToLeave: number) {
    let value: any = {
      new_value: reasonToLeave,
      attribute: 'reason_to_leave',
      apply_from: DateHelper.getFormatted2Date(new Date())
    };
    if (typeof workRecord.reasonToLeave.valueId !== 'undefined') {
      value.id = workRecord.reasonToLeave.valueId;
    }

    return client.update(workRecord.id, WORK_RECORD, {attributes: requestService.upgradeForUpdate([value])});
  }

  public updateTrialStatus(workRecord: WorkRecordInterface, data: TrialStatusStateInterface) {
    const body = {
      attributes: [],
      trial_status: data.trialStatus,
      trial_end_date: data.trialEndDate ? DateHelper.getFormatted2Date(data.trialEndDate) : ''
    }
    return client.update(workRecord.id, WORK_RECORD, body);
  }

  public updateNextEmployer(workRecord: WorkRecordInterface, nextEmployer: string) {
    let value: any = {
      new_value: nextEmployer,
      attribute: 'next_employer',
      apply_from: DateHelper.getFormatted2Date(new Date())
    }
    if (typeof workRecord.nextEmployer.valueId !== 'undefined') {
      value.id = workRecord.nextEmployer.valueId;
    }

    return client.update(workRecord.id, WORK_RECORD, {attributes: requestService.upgradeForUpdate([value])});
  }

  public updateSkills(wrId: number, skills: Array<Skill>, formValues: any) {
    let diff: any = diffService.updateSkills([], skills, formValues);
    return client.update(wrId, WORK_RECORD, {attributes: diff});
  }

  private buildWorkRecordProperty(workRecord: WorkRecordInterface, formValues: any) {
    let property: any = {};
    if (formValues.startDate) {
      property['start_date'] = DateHelper.getFormatted2Date(formValues.startDate);
    }
    if (formValues.endDate) {
      property['end_date'] = DateHelper.getFormatted2Date(formValues.endDate);
    }
    if (formValues.trialPeriod) {
      property['trial_status'] = TRIAL_STATUSES.IN_PROGRESS.name;
      property['trial_end_date'] = DateHelper.getFormatted2Date(formValues.trialEndDate);
    } else {
      property['trial_status'] = TRIAL_STATUSES.NOT_SET.name;
      property['trial_end_date'] = '';
    }
    return property;
  }
}

export default new WorkRecordHelper();
