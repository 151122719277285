import { Staff } from '../../interfaces/Staff';
import { WorkRecord } from '../../interfaces/WorkRecord';
import { Skill } from '../../interfaces/entity/Skill';
import { SkillDiff } from '../../interfaces/entity/SkillDiff';
import ValueNull from '../../models/ValueNull';
import PositionDescriptionForm from '../../models/forms/PositionDescrioptionForm';
import EditProfileForm from '../../models/forms/EditProfileForm';
import DateHelper from '../../utils/date/DateHelper';
import { ATTRIBUTES_MAP, COMMON, TRIAL_STATUSES } from '../../constants';

class DiffService {
  public getAttributesDiff(
    originAttributes: Map<string, any>,
    formAttributes: any,
    entity: string,
    isNew: boolean = false
  ) {
    let diff: any = [];
    let backendKeys: Array<any> = [];
    switch (entity) {
      case 'wr' :
        backendKeys = ATTRIBUTES_MAP.workRecordBackend;
        break;
      case 'staff':
        backendKeys = ATTRIBUTES_MAP.staffBackend;
        break;
    }

    if (formAttributes.hasOwnProperty('country') && formAttributes.hasOwnProperty('city')) {
      const countryName = formAttributes['country'] !== COMMON.NOT_SPECIFIED ? formAttributes['country'] : '';
      const cityName = countryName ? formAttributes['city'] : '';
      if (isNew) {
        if (countryName) {
          formAttributes['physicalLocation'] = {
            city_name: cityName,
            country_name: countryName
          };
        }
      } else {
        formAttributes['physicalLocation'] = {
          city_name: cityName,
          country_name: countryName
        };
      }

    }

    const attributes: Array<string> = Object.keys(formAttributes).filter((key: any) => {
      return !(['city','country'].includes(key));
    });
    attributes.forEach((key: any) => {
      const attribute = originAttributes.get(key);
      const originalValue = (attribute) ? attribute.value : new ValueNull();

      let formValue: any = formAttributes[key];

      if (typeof formValue !== 'undefined' && formValue !== null) {
        formValue = (formValue instanceof Date)
          ? DateHelper.getFormattedDate(formValue)
          : (typeof formValue === 'object' && key !== 'physicalLocation') ? formValue.id : formValue;
      }

      const backEndKey = backendKeys[key];
      if (backEndKey && this.isAttributeChanged(formValue, originalValue.getValue())) {
        if (isNew) {
          diff.push(this.prepareCreateAttribute(formValue, backEndKey));
        } else {
          diff.push(this.prepareUpdateAttribute(originalValue, formValue, backEndKey));
        }
      }
    });

    return diff;
  }

  public addSkills(diff: Array<any>, formValues: any): Array<any> {
    formValues.hardSkills.forEach((skill: Skill) => this.pushSkill(diff, skill));
    formValues.softSkills.forEach((skill: Skill) => this.pushSkill(diff, skill));
    formValues.languageSkills.forEach((skill: Skill) => this.pushSkill(diff, skill));

    return diff;
  }

  public updateSkills(diff: Array<any>, skills: Array<Skill>, formValues: any): Array<any> {
    const localDiff = this.getSkillDiff(skills, formValues.skills);
    const applyFrom = DateHelper.getFormatted2Date(formValues.activeFrom);
    localDiff.forEach((sDiff: SkillDiff) => {
      let skill: any = {
        attribute: 'skill',
      };
      switch (sDiff.type) {
        case 'add':
          skill.apply_from = applyFrom;
          skill.action = 'create';
          skill.new_level = sDiff.skill.level;
          skill.new_value = sDiff.skill.id;
          break;
        case 'change':
          skill.apply_from = applyFrom;
          skill.action = 'create';
          skill.new_level = sDiff.skill.level;
          skill.new_value = sDiff.skill.id;
          break;
        case 'remove':
          skill.end_date = applyFrom;
          skill.action = 'close';
          break;
      }
      if (sDiff.skill.attributeId) {
        skill.id = sDiff.skill.attributeId;
      }
      diff.push(skill);
    });
    return diff;
  }

  public getSkillDiff(skills: Array<Skill>, formSkills: Array<Skill>): Array<SkillDiff> {
    const skillsMap: { [key: number]: Skill } = [];
    skills.forEach((skill: Skill) => {
      skillsMap[skill.id] = skill;
    });
    const skillsMapKeys: Array<string> = Object.keys(skillsMap);
    const formSkillsMap: { [key: number]: Skill } = [];
    formSkills.forEach((skill: Skill) => {
      formSkillsMap[skill.id] = skill;
    });
    const formSkillsMapKeys: Array<string> = Object.keys(formSkillsMap);

    const localDiff: Array<SkillDiff> = [];
    //Added | Changed
    formSkillsMapKeys.forEach((key: string) => {
      const numberKey = Number(key);
      const oldSkill = skillsMap[numberKey];
      const newSkill = formSkillsMap[numberKey];
      if (skillsMapKeys.indexOf(key) === -1) {
        localDiff.push({
          skill: newSkill,
          type: 'add'
        })
      } else {
        if (oldSkill.level !== newSkill.level) {
          newSkill.attributeId = oldSkill.attributeId;
          localDiff.push({
            skill: newSkill,
            type: 'change',
            oldLevel: oldSkill.level,
            newLevel: newSkill.level,
          })
        }
      }
    });
    //Removed
    skillsMapKeys.forEach((key: string) => {
      if (formSkillsMapKeys.indexOf(key) === -1) {
        localDiff.push({
          skill: skillsMap[Number(key)],
          type: 'remove'
        })
      }
    });
    return localDiff;
}

  public getWRPropertyDiff(workRecord: WorkRecord, formValues: PositionDescriptionForm) {
    let diff: any = {};
    if (formValues.startDate && formValues.startDate.getTime() !== workRecord.startDate?.getTime()) {
      diff['start_date'] = DateHelper.getFormatted2Date(formValues.startDate);
    }
    if (formValues.endDate !== workRecord.endDate) {
      diff['end_date'] = formValues.endDate ? DateHelper.getFormatted2Date(formValues.endDate) : '';
    }

    if (formValues.trialEndDate !== workRecord.trialEndDate) {
      diff['trial_end_date'] = formValues.trialEndDate ? DateHelper.getFormatted2Date(formValues.trialEndDate) : '';
    }
    
    if (workRecord.isTrial !== formValues.trialPeriod) {
      diff['trial_status'] = TRIAL_STATUSES.NOT_SET.name;
      diff['trial_end_date'] = '';
      if (formValues.trialPeriod && formValues.trialEndDate
        && formValues.trialEndDate.getTime() !== workRecord.trialEndDate?.getTime()) {
        diff['trial_end_date'] = DateHelper.getFormatted2Date(formValues.trialEndDate);
      }
      if (formValues.trialPeriod !== workRecord.isTrial) {
        if (formValues.trialPeriod) {
          diff['trial_status'] = TRIAL_STATUSES.IN_PROGRESS.name;
        } else {
          diff['trial_status'] = TRIAL_STATUSES.NOT_SET.name;
          diff['trial_end_date'] = '';
        }
      }
    }

    return diff;
  }

  public getStaffPropertyDiff(staff: Staff, formValues: EditProfileForm) {
    let diff: any = {};
    if (formValues.username !== staff.username) {
      diff['username'] = formValues.username;
    }
    if (formValues.alumni !== staff.alumni) {
      diff['alumni'] = (formValues.alumni) ? '1' : '0';
    }
    if (formValues.companyStartDate && formValues.companyStartDate.getTime() !== staff.companyStartDate?.getTime()) {
      diff['company_start_date'] = DateHelper.getFormatted2Date(formValues.companyStartDate);
    }

    return diff;
  }

  private isAttributeChanged(newValue: any, oldValue: any): boolean {
    if (newValue && typeof newValue === 'object' &&
      newValue.hasOwnProperty('country_name') &&
      newValue.hasOwnProperty('city_name') &&
      !newValue['country_name'] && !newValue['city_name'] && oldValue === null) {
      return false;
    }
    return (oldValue === null && newValue !== undefined && newValue !== '' && newValue !== null && !Number.isNaN(newValue))
      || (newValue != oldValue && oldValue !== null);
  }

  private prepareCreateAttribute(formValue: any, key: string) {
    let newAttribute: any = {
      action: 'create',
      value: this.getValue(formValue),
      attribute: key
    }

    return newAttribute;
  }

  private prepareUpdateAttribute(originalValue: any, formValue: any, key: string) {
    let newAttribute: any = {
      action: 'create',
      new_value: this.getValue(formValue),
      attribute: key
    }
    if (originalValue.valueId) {
      newAttribute.id = originalValue.valueId;
    }

    return newAttribute;
  }

  private getValue(formValue: any) {
    let value;
    if (typeof formValue === 'boolean') {
      value = (formValue) ? '1' : '0';
    } else {
      value = formValue;
    }

    return value;
  }

  private pushSkill(diff: Array<any>, skill: Skill) {
    diff.push({
      action: 'create',
      value: skill.id,
      level: skill.level,
      attribute: 'skill'
    });
  }
}

export default new DiffService();
