import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Form, Formik } from 'formik';
import { toastr } from 'react-redux-toastr';

// Store
import store from '../../../store/store';
import { setOverlayIsVisible } from '../../../store/overlay/actions';
import { loaderActions } from '../../../store/loader/actions';
import { getDictionaryCountries, getDictionaryReportFilters } from '../../../store/dictionary/actions';

import HRDashboardClient from '../../../services/http/HRDashboardClient';

import DateHelper from '../../../utils/date/DateHelper';
import { HeadcountByTypeView } from './HeadcountByTypeView/HeadcountByTypeView';
import { HeadcountByPeriod } from './HeadcountByPeriod/HeadcountByPeriod';
import { HeadcountDetailsList } from './HeadcountDetailsList/HeadcountDetailsList';
import { DateRangePickerField, periodsRange } from '../../Forms/DateRangePickerField';
import { NoPermissions } from '../../Security/NoPermissions/NoPermissions';
import { TypeaheadField } from '../../Forms/TypeaheadField';

// Constants
import { HR_DASHBOARD, HR_DASHBOARD_CONTENT_TABS, HR_DASHBOARD_TYPE_VIEWS } from '../../../constants';

// Styles
import './headcount.scss';

type DataType = {
  locations: Array<any>;
  orgStructures: Array<any>;
  departments: Array<any>;
  overview: any;
}

export const Headcount: React.FC = () => {
  const [activeTab, setActiveTab] = useState<string>(HR_DASHBOARD.DEFAULT_CONTENT_TAB.key);
  const [activePeriod, setActivePeriod] = useState<string>('M');
  const [activeTypeView, setActiveTypeView] = useState<string>(HR_DASHBOARD_TYPE_VIEWS.bar);
  const [countryList, setCountryList] = useState([]);
  const [country, setCountry] = useState<string>('');
  const [periodRange, setPeriodRange] = useState<string>(periodsRange.lastQuarter);
  const [fromDate, setFromDate] = useState<Date | null>(null);
  const [toDate, setToDate] = useState<Date | null>(null);
  const [filters, setFilters] = useState<any>({});
  const [data, setData] = useState<DataType | null>(null);
  const [noPermissions, setNoPermissions] = useState<boolean>(false);

  const tabs: any = HR_DASHBOARD_CONTENT_TABS;
  const typeView: any = Object.values(HR_DASHBOARD_TYPE_VIEWS);
  const dispatch = useDispatch();

  const { reportFilters, countries } = useSelector((store: any) => store.dictionary);
  const periodFilter = reportFilters?.headcount_dynamic_report?.period_type;

  useEffect(() => {
    dispatch(getDictionaryReportFilters())
  }, []);

  useEffect(() => {
    dispatch(getDictionaryCountries())
  }, []);

  useEffect(() => {
    if (countries?.length > 0) {
      setCountryList(countries);
    }
  }, [countries]);

  const sortByHeadcount = (list: Array<any>) => {
    return list.sort(function (a, b) {
      if (a.headcount > b.headcount) {
        return -1;
      } else if (a.headcount < b.headcount) {
        return 1;
      } else {
        return 0;
      }
    });
  }

  useEffect(() => {
    const params: any = {
      period_range: periodRange,
      period_type: activePeriod
    };
    if (periodRange === periodsRange.custom) {
      if (fromDate) {
        Object.assign(params, { date_from: DateHelper.getFormatted2Date(fromDate) });
      } else {
        Object.assign(params, { date_from: DateHelper.getFormatted2Date(new Date('2008-04-01')) });
      }
    
      if (toDate) {
        Object.assign(params, { date_to: DateHelper.getFormatted2Date(toDate) });
      } else {
        Object.assign(params, { date_to: DateHelper.getFormatted2Date(new Date()) });
      }
    }
    if (country) {
      Object.assign(params, { location: country });
    }
    if (JSON.stringify(params) !== JSON.stringify(filters)) {
      setFilters(params);
    }
  }, [periodRange, activePeriod, country, fromDate, toDate]);
  
  useEffect(() => {
    if (Object.keys(filters).length === 0) return;
    store.dispatch(setOverlayIsVisible(true));
    store.dispatch(loaderActions.showLoader());
    HRDashboardClient.getHeadcountData(filters)
      .then((data: any) => {
        const locations = [data.overview, ...data.locations];
        const orgStructures = data.org_structures;
        const departments = data.departments?.length ? sortByHeadcount(data.departments) : [];
        const overview = data.overview;
        setData({ locations, orgStructures, departments, overview });
      })
      .catch((error: any) => {
        if (error.status === 403) {
          setNoPermissions(true);
          toastr.error('', 'No permissions to view this data');
        } else {
          toastr.error('', 'This data is unavailable');
        }
      })
      .finally(() => {
        store.dispatch(setOverlayIsVisible(false));
        store.dispatch(loaderActions.hideLoader());
      });
  }, [filters])

  const defineContainerHeight = () => {
    const documentElementHeight: number = window.document.documentElement.clientHeight;
    const headerElement = document.getElementsByClassName('header-wrapper')[0] as HTMLElement;
    if (documentElementHeight && headerElement) {
      return `${documentElementHeight - headerElement.clientHeight - 30}px`;
    } else {
      return 0;
    }
  }

  return (
    <section className="h-100" style={{"background": "#e9f0f3"}}>
      {
        noPermissions
          ? <NoPermissions/>
          : (
            <section>
              <br/>
              <div className="data-wrapper" style={{height: defineContainerHeight()}}>
                <div className="sticky-wrapper">
                  <div className="details-control">
                    <div className="row">
                      <div className="col-auto mr-auto">
                        <ul className="tabs">
                          {
                            Object.keys(tabs).map((key: string, i: number) => {
                              return (
                                <li
                                  className={`tab${tabs[key].key === activeTab ? ' active' : ''}`}
                                  key={ i }
                                  onClick={ () => setActiveTab(tabs[key].key) }>
                                  { tabs[key].label }
                                </li>
                              )
                            })
                          }
                        </ul>
                      </div>
                      <div className="markers-wrapper">
                        <span className="marker non-billable">non-billable</span>
                        <span className="marker billable">billable</span>
                        <span className="marker joined">joined</span>
                        <span className="marker left">left</span>
                      </div>
                    </div>
                  </div>
                  <div className="headcount-dynamic-control">
                    <div className="row">
                      <div className="col-auto mr-auto">
                        <div className="filter-group">
                          <DateRangePickerField
                            setPeriodRange={ setPeriodRange }
                            setFromDate={ setFromDate }
                            setToDate={ setToDate }
                          />
                          <HeadcountByPeriod
                            periodFilter={ periodFilter }
                            activePeriod={ activePeriod }
                            setActivePeriod={ setActivePeriod }
                          />
                          <HeadcountByTypeView
                            activeTypeView={ activeTypeView }
                            setActiveTypeView={ setActiveTypeView }
                            typeView={ typeView }
                          />
                  
                          {
                            activeTab !== 'locations' && (
                              <div className="country-wrapper">
                                <span className="custom-label">Country</span>
                                <Formik initialValues={{ country: country }} onSubmit={() => {}}>
                                  {
                                    ({ values, setFieldValue }) => (
                                      <Form className="edb-form">
                                        <TypeaheadField
                                          className="form-control"
                                          id="country"
                                          name="country"
                                          labelKey="name"
                                          multiple={ false }
                                          options={ countryList }
                                          onChange={ (selected: any) => {
                                            if (selected && selected[0]) {
                                              setFieldValue('country', selected[0]);
                                              setCountry(selected[0].id);
                                            } else {
                                              setFieldValue('country', '');
                                              setCountry('');
                                            }
          
                                          } }
                                          clearButton={ !!values.country }
                                        />
                                      </Form>
                                    )
                                  }
                                </Formik>
                              </div>
                            )
                          }
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <HeadcountDetailsList
                  activeTab={ activeTab }
                  activePeriod={ activePeriod }
                  data = { data }
                  chartType={ activeTypeView }
                />
              </div>
            </section>
          )
      }
    </section>
  );
}
