export default class ValueHelper {

  static getValue(data: any, fieldName: string) {
    const rootOfMethod: string = fieldName,
      method = `get${rootOfMethod[0].toUpperCase()}${rootOfMethod.slice(1)}Value`;

    // @ts-ignore
    if (ValueHelper[method]) {
      // @ts-ignore
      return ValueHelper[method](data);
    } else {
      return ValueHelper.getOtherValue(data);
    }
  }

  static getPhysical_locationValue(value: any) {
    let location = '';
    if(value.country_name){
      location = value.country_name;
    }

    if(value.city_name){
      location = location + ', ' +  value.city_name;
    }
    
    return location || null;
  }

  static getLocationValue(value: any) {
    let location = value && value.name;
    if (value && value.country) {
      location += `, ${value.country.name}`;
    }
    return location || null;
  }

  static getHeadValue(value: any) {
    return value ? value.head.full_name : null;
  }

  static getOrgStructureValue(value: any) {
    return value?.org_structure ? value.org_structure.name : null;
  }

  static getOrgStructureManagerValue(value: any) {
    return value?.org_structure ? value.org_structure.head.full_name : null;
  }

  static getDepartmentValue(value: any) {
    return value ? value.department_full_name : null;
  }

  static getDepartmentManagerValue(value: any) {
    return value?.department_manager ? value.department_manager.full_name : null;
  }

  static getSubDepartmentValue(value: any) {
    return value ? value.sub_department_full_name : null;
  }

  static getSubDepartmentManagerValue(value: any) {
    return value?.sub_department_manager ? value.sub_department_manager.full_name : null;
  }

  static getLine_managerValue(value: any) {
    return value ? value.full_name : null;
  }

  static getPeople_partnerValue(value: any) {
    return value ? value.full_name : null;
  }

  static getMentorValue(value: any) {
    return value ? value.full_name : null;
  }

  static getIs_billableValue(value: any) {
    return value === '1' ? 'Yes' : 'No';
  }

  static getGr_levelValue(value: any) {
    return value ? value.level + ' ' + value.title : null;
  }

  static getOtherValue(value: any) {
    return (typeof value === 'object') ? value.name : value;
  }

  static getNameReversed(value: any) {
    return value ? `${value.first_name} ${value.last_name}` : null;
  }
}