import {Value as ValueInterface} from '../interfaces/Value';
import DateHelper from '../utils/date/DateHelper';

export default class ValueDate implements ValueInterface<Date> {
  valueId: number | null = null;
  value: Date;

  constructor(value: Date = new Date()) {
    this.value = value;
  }

  getValue(): string {
    return DateHelper.getFormattedDate(this.value);
  }
}
