import React, { FunctionComponent, useEffect, useState } from 'react';
import { useStore } from 'react-redux';

// Services
import dictionaryClient from './../../../services/http/DictionaryClient';
import EmployeeTableHelper from '../../../services/data/EmployeeTableHelper';

import store from '../../../store/store';
import { setEtAttributes } from '../../../store/employeeTable/actions';
import { EMPLOYEE_TABLE_STATE } from '../../../store/reducers';

// Components
import { EmployeeTable } from './EmployeeTable';
import { Spinner } from '../../Spinner/Spinner';

export const EmployeeTableWrapper: FunctionComponent = () => {
  const { getState } = useStore();
  const [isLoaded, setIsLoaded] = useState<boolean>(false);
  const [columns, setColumns] = useState<Array<any>>(getState()[EMPLOYEE_TABLE_STATE].attributes);

  useEffect(() => {
    if (isLoaded) {
      return;
    }
    dictionaryClient.getAllAttribute().then((data) => {
      const converted = EmployeeTableHelper.convertAttributesToColumns(data.columns);
      setColumns(converted);
      store.dispatch(setEtAttributes(converted));
      setIsLoaded(true);
    });
  }, [isLoaded]);

  if (isLoaded) {
    return (
      <EmployeeTable columns={ columns } />
    );
  }

  return <Spinner/>;
}