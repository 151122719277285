import {CVInterface} from "../../interfaces/CVInterface";
import CVModel from "../../models/CV";

class CVMapper {

  public createCVFromResponse(data: any): CVInterface {
    const cvModel: CVInterface = new CVModel();
    cvModel.id = data.id;
    cvModel.startDate = data.start_date;
    cvModel.updatedAt = data.updated_at;
    cvModel.status = data.status;

    for (let key in data.cv.data) {
      Object.assign(cvModel.data, {[key]: data.cv.data[key]});
    }
    return cvModel;
  }
}

export default new CVMapper();