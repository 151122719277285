import {Skill} from "../../../../interfaces/entity/Skill";

export interface SkillEditStateInterface {
  skills: Array<Skill>,
  activeFrom: Date
}

const initialState: SkillEditStateInterface = {
  skills: [],
  activeFrom: new Date()
}

export default class SkillEditState {
  public static generateFromList(list: Array<Skill>): SkillEditStateInterface {
    let state = initialState;
    state.skills = list;
    return state;
  }
}
