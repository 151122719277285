import React from 'react';

import { WorkRecord } from '../../../interfaces/WorkRecord';
import { SkillSetInterface } from '../../../interfaces/SkillSetInterface';

import { PopupIndexedTrigger } from '../../Popup/PopupIndexedTrigger';
import { PopupIndexed } from '../../Popup/PopupIndexed';
import { SkillEditForm } from '../Forms/SkillEdit/SkillEditForm';
import { WRSkillTree } from './WRSkillTree';
import { AttributeWithPermission } from '../Common/Attribute/AttributeWithPermission';

type PropsType = {
  title: string,
  type: 'hardSkills' | 'softSkills' | 'languageSkills',
  skills: SkillSetInterface,
  workRecord: WorkRecord
}

export const WRSkill: React.FC<PropsType> = (props: PropsType) => {
  const { title, type, skills, workRecord } = { ...props };
  return (
    <div className="card">
      <div className="card-body">
        <p className="card-title">
          { title }
          <AttributeWithPermission
            name="skill"
            wr={ workRecord }
            permission="edit"
            attribute={
              <>
                <PopupIndexedTrigger popupName="editSkill" popupIndex={ type }>
                  <span className="popup-button">
                    {
                      (skills[type]?.length)
                        ? (
                          <>
                            <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                              <path d="M-0.000732422 9.64069V11.6674C-0.000732422 11.854 0.145934 12.0007 0.332601 12.0007H2.35927C2.44593 12.0007 2.5326 11.9674 2.5926 11.9007L9.8726 4.62736L7.3726 2.12736L0.0992676 9.40069C0.032601 9.46736 -0.000732422 9.54736 -0.000732422 9.64069ZM11.8059 2.69402C12.0659 2.43402 12.0659 2.01402 11.8059 1.75402L10.2459 0.194023C9.98593 -0.0659766 9.56593 -0.0659766 9.30593 0.194023L8.08593 1.41402L10.5859 3.91402L11.8059 2.69402Z" fill="#0A88EC"/>
                            </svg>Edit
                          </>
                        )
                        : (
                          <>
                            <svg width="10" height="10" viewBox="0 0 10 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                              <path d="M8.99992 5.66634H5.66659V8.99967C5.66659 9.36634 5.36659 9.66634 4.99992 9.66634C4.63325 9.66634 4.33325 9.36634 4.33325 8.99967V5.66634H0.999919C0.633252 5.66634 0.333252 5.36634 0.333252 4.99967C0.333252 4.63301 0.633252 4.33301 0.999919 4.33301H4.33325V0.999674C4.33325 0.633008 4.63325 0.333008 4.99992 0.333008C5.36659 0.333008 5.66659 0.633008 5.66659 0.999674V4.33301H8.99992C9.36659 4.33301 9.66659 4.63301 9.66659 4.99967C9.66659 5.36634 9.36659 5.66634 8.99992 5.66634Z" fill="#0A88EC"/>
                            </svg>Add
                          </>
                        )
                    }
                  </span>
                </PopupIndexedTrigger>
                <PopupIndexed
                  popupName="editSkill"
                  popupIndex={ type }
                  id={ type }
                  title=""
                  size="lg">
                  <SkillEditForm
                    title={ `Edit ${title}` }
                    wrId={ workRecord.id }
                    wrSkills={ skills[type] }
                    type={ type }
                    popupName="editSkill"
                    popupIndex={ type }
                  />
                </PopupIndexed>
              </>
            }
          >
          </AttributeWithPermission>
        </p>
        <WRSkillTree skills={ skills[type] } />
      </div>
    </div>
  );
};