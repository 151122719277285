import React, { useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { toastr } from 'react-redux-toastr';

import store from '../../../store/store';
import { GlobalState } from '../../../store/types';
import { cvActions } from '../../../store/cv/actions';
import { loaderActions } from '../../../store/loader/actions';
import { setOverlayIsVisible } from '../../../store/overlay/actions';

import CVClient from '../../../services/http/CVClient';

import CVMapper from '../../../mappers/response/CVMapper';

import DateHelper from '../../../utils/date/DateHelper';

type Params = {
  staffId: string,
  wrId: string
}

type CvItem = {
  id: number,
  code: string,
  startDate: Date,
  endDate: Date | null,
  cvId: number,
  cvStatus: string,
  cvDate: Date,
  title: any | null
}

export const WRCvList: React.FC = () => {
  const params = useParams<Params>();
  const cParams = {
    staffId: Number(params.staffId),
    wrId: Number(params.wrId)
  };
  
  const { staff } = useSelector((state: GlobalState) => state.staff);
  
  const [data, setData] = useState<Array<CvItem> | null>(null);
  
  useEffect(() => {
    store.dispatch(setOverlayIsVisible(true));
    store.dispatch(loaderActions.showLoader());
    CVClient.getList(cParams.staffId)
      .then((response: any) => {
        if (response.data) {
          setData(response.data);
        }
      })
      .catch((error: any) => {
        console.error(error);
      })
      .finally(() => {
        store.dispatch(setOverlayIsVisible(false));
        store.dispatch(loaderActions.hideLoader());
      });
  }, [cParams.staffId]);
  
  const history = useHistory();
  
  const handleClick = (cvId: number) => {
    store.dispatch(setOverlayIsVisible(true));
    store.dispatch(loaderActions.showLoader());
    CVClient.clone(cParams.wrId, cvId)
      .then((response: any) => {
        if (response.status === 200) {
          const data = CVMapper.createCVFromResponse(response.data);
          store.dispatch(cvActions.setData(data));
          toastr.success('', 'CV has been created successfully');
          history.push(`/staff/${cParams.staffId}/work-records/${cParams.wrId}/cv/edit`);
        }
      })
      .catch((error: any) => {
        console.error(error);
        toastr.error('','Oops, CV cloning failed, try again please');
      })
      .finally(() => {
        store.dispatch(setOverlayIsVisible(false));
        store.dispatch(loaderActions.hideLoader());
      });
  }
  
  if (!data) return <></>
  
  return (
    <section className="border-bottom">
      { staff && <p><b>CV data found in other Work Records of { staff.firstName?.value } { staff.lastName?.value }:</b></p> }
      {
        data.map((item: CvItem, index: number) => {
          return (
            <div className="d-flex align-items-center justify-content-between mb-2" key={ index }>
              <p
                className="m-0">WR { item.code } { item.title ? item.title.name : '' } { DateHelper.getFormattedDate(new Date(item.startDate)) } - { item.endDate ? DateHelper.getFormattedDate(new Date(item.endDate)) : 'Now' } CV
                as of { DateHelper.getFormattedDate(new Date(item.cvDate)) } in { item.cvStatus } status</p>
              <button type="button" className="edb-btn edb-btn--primary" onClick={ () => handleClick(item.cvId) }>Clone CV data</button>
            </div>
          );
        })
      }
    </section>
  );
}