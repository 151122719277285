import React, { useEffect, useState } from 'react';
import {toastr} from 'react-redux-toastr';

// Store
import store from '../../../store/store';
import { setOverlayIsVisible } from '../../../store/overlay/actions';
import { loaderActions } from '../../../store/loader/actions';

import HRDashboardClient from '../../../services/http/HRDashboardClient';
import DictionaryClient from '../../../services/http/DictionaryClient';

import { periodsRange } from '../../Forms/DateRangePickerField';
import { NoPermissions } from '../../Security/NoPermissions/NoPermissions';
import DateHelper from '../../../utils/date/DateHelper';

import { LeaversControl } from './LeaversControl/LeaversControl';
import { LeaversDetails } from './LeaversDetails/LeaversDetails';

import {
  HR_DASHBOARD,
  HR_DASHBOARD_CONTENT_TABS,
  HR_DASHBOARD_LEAVERS_EMPLOYMENT_DURATION,
  HR_DASHBOARD_LEAVERS_TURNOVER_TYPES
} from '../../../constants';

// Styles
import './leavers.scss';

export const Leavers: React.FC = () => {
  const [activeTab, setActiveTab] = useState<string>(HR_DASHBOARD.DEFAULT_CONTENT_TAB.key);
  const [country, setCountry] = useState<string>('');
  const [turnover, setTurnover] = useState<string>(HR_DASHBOARD_LEAVERS_TURNOVER_TYPES.all.key);
  const [employmentDuration, setEmploymentDuration] = useState<string>(HR_DASHBOARD_LEAVERS_EMPLOYMENT_DURATION.all.key);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [locations, setLocations] = useState<Array<any>>([]);
  const [orgStructures, setOrgStructures] = useState<Array<any>>([]);
  const [departments, setDepartments] = useState<Array<any>>([]);
  const [reasonsToLeave, setReasonsToLeave] = useState<Array<any>>([]);
  const [periodRange, setPeriodRange] = useState<string>(periodsRange.lastQuarter);
  const [fromDate, setFromDate] = useState<Date | null>(null);
  const [toDate, setToDate] = useState<Date | null>(null);
  const [noPermissions, setNoPermissions] = useState<boolean>(false);

  const tabs: any = HR_DASHBOARD_CONTENT_TABS;

  const getData = () => {
    if (reasonsToLeave.length <= 0) {
      DictionaryClient.getReasonsToLeave().then((data: any) => {
        setReasonsToLeave(data);
      });
    }
    store.dispatch(setOverlayIsVisible(true));
    store.dispatch(loaderActions.showLoader());
    const params: any = {
      periodRange,
      duration: employmentDuration,
      turnoverType: turnover
    };
    if (fromDate) {
      Object.assign(params, { dateFrom: DateHelper.getFormatted2Date(fromDate) });
    }
    if (toDate) {
      Object.assign(params, { dateTo: DateHelper.getFormatted2Date(toDate) });
    }
    if (country) {
      Object.assign(params, { location: country });
    }

    HRDashboardClient.getLeaversData(params).then((data: any) => {
      const companyData = {
        location_name: data.label,
        data: data.reasons,
        amount: data.reasons.reduce((acc: number, item: any) => {
          acc += item.amount;
          return acc;
        }, 0)
      };
      data.locations.unshift(companyData);
      setLocations(data.locations);
      setOrgStructures(data.org_structures);
      setDepartments(data.departments);
      store.dispatch(setOverlayIsVisible(false));
      store.dispatch(loaderActions.hideLoader());
    }).catch((error: any) => {
      if (error.status === 403) {
        setNoPermissions(true);
        toastr.error('', 'No permissions to view this data');
      } else {
        toastr.error('', 'This data is unavailable');
      }
      store.dispatch(setOverlayIsVisible(false));
      store.dispatch(loaderActions.hideLoader());
    });
  }

  useEffect(() => {
    getData();
  }, [country, turnover, employmentDuration, periodRange, fromDate, toDate]);

  return (
    <section className="h-100" style={{"background": "#e9f0f3"}}>
      {
        noPermissions
          ? <NoPermissions/>
          : (
            <section>
              <br/>
              <div className="leavers-data-wrapper" style={{"height": `calc(100vh - 100px)`}} id="wrapper">
                <div>
                  <div className="details-control">
                    <div className="row">
                      <div className="col-auto mr-auto">
                        <ul className="tabs">
                          {
                            Object.keys(tabs).map((key: string, i: number) => {
                              return (
                                <li
                                  className={`tab${tabs[key].key === activeTab ? ' active' : ''}`}
                                  key={i}
                                  onClick={() => setActiveTab(tabs[key].key)}>
                                  {tabs[key].label}
                                </li>
                              )
                            })
                          }
                        </ul>
                      </div>
                    </div>
                  </div>
                  <LeaversControl
                    activeTab={ activeTab }
                    country={ country }
                    setCountry={ setCountry }
                    turnover={ turnover }
                    setTurnover={ setTurnover }
                    employmentDuration={ employmentDuration }
                    setEmploymentDuration={ setEmploymentDuration }
                    setPeriodRange={ setPeriodRange }
                    setFromDate={ setFromDate }
                    setToDate={ setToDate }
                  />
                </div>
                <LeaversDetails
                  activeTab={ activeTab }
                  locations={ locations }
                  orgStructures={ orgStructures }
                  departments={ departments }
                  reasonsToLeave={ reasonsToLeave }
                />
              </div>
            </section>
          )
      }
    </section>
  );
}
