import matchSorter from 'match-sorter';

export function textFilterFn(rows: Array<any>, id: string, filterValue: any) {
  return rows.filter((row) => {
    const rowValue = row.values[id]
    return rowValue !== undefined
      ? String(rowValue).toLowerCase().startsWith(String(filterValue).toLowerCase())
      : true
  });
}

export function fuzzyTextFilterFn(rows: Array<any>, id: string, filterValue: any) {
  return matchSorter(rows, filterValue, {keys: [row => row.values[id]]});
}

fuzzyTextFilterFn.autoRemove = (val: boolean) => !val

export function multipleFilterFn(rows: Array<any>, id: string, filterValue: any) {
  if (filterValue.length === 0) {
    return rows;
  }
  return rows.filter((row) =>  {
    const rowValue = row.values[id];
    if (Array.isArray(rowValue)) {
      const filteredArray = rowValue.filter(value => filterValue.includes(value));
      return filteredArray.length > 0;
    }
    return filterValue.indexOf(rowValue) !== -1;
  });
}

export function betweenDatesFilterFn(rows: Array<any>, id: string, filterValue: any) {
  const from = filterValue[0];
  const to = filterValue[1];
  return rows.filter((row) =>  {
    const rowValue = row.values[id];
    const date = new Date(rowValue);
    if (from && to) {
      return (from <= date && date <= to);
    } else if (from && !to) {
      return (from <= date);
    } else if (!from && to) {
      return (date <= to);
    } else {
      return true;
    }
  });
}
