const dateTimeFormat = new Intl.DateTimeFormat(
  'en',
  {
    year: 'numeric',
    month: 'short',
    day: '2-digit',
    hour: 'numeric',
    minute: 'numeric',
  }
)

const dateTimeFormat2 = new Intl.DateTimeFormat(
  'en',
  {
    year: 'numeric',
    month: '2-digit',
    day: '2-digit'
  }
)

export const dateTimeFormatYear = new Intl.DateTimeFormat(
  'en',
  {
    year: 'numeric'
  }
)

export default class DateHelper {
  public static getFormattedDate(date: Date): string {
    const [{value: month}, , {value: day}, , {value: year}] = dateTimeFormat.formatToParts(date)

    return `${month} ${day}, ${year}`;
  }

  public static getFormatted2Date(date: Date): string {
    const [{value: month}, , {value: day}, , {value: year}] = dateTimeFormat2.formatToParts(date)

    return `${year}-${month}-${day}`;
  }

  public static getFormatted3Date(date: Date): string {
    const [{value: month}, , {value: day}, , {value: year}, , {value: hours}, , {value: minutes}] =
      dateTimeFormat.formatToParts(date)

    return `${month} ${day}, ${year} ${hours}:${minutes}`;
  }

  public static getMonthShort(date: Date): string {
    const [{value: month}] = dateTimeFormat.formatToParts(date);

    return month;
  }
}
