import React, { useEffect, useState } from 'react';
import { toastr } from 'react-redux-toastr';
import FileSaver from 'file-saver';

// Services
import HRDashboardClient from '../../../services/http/HRDashboardClient';

// Store
import store from '../../../store/store';
import { setOverlayIsVisible } from '../../../store/overlay/actions';
import { loaderActions } from '../../../store/loader/actions';

// Components
import { DownloadIcon } from '../../Icon/DownloadIcon';
import { TurnoverControl } from './TurnoverControl/TurnoverControl';
import { periodsRange } from '../../Forms/DateRangePickerField';
import { NoPermissions } from '../../Security/NoPermissions/NoPermissions';
import { TurnoverDetails } from './TurnoverDetails/TurnoverDetails';
import DateHelper from '../../../utils/date/DateHelper';

// Constants
import { HR_DASHBOARD, HR_DASHBOARD_CONTENT_TABS } from '../../../constants';

// Styles
import './turnover.scss'

export const Turnover: React.FC = () => {
  const [activeTab, setActiveTab] = useState<string>(HR_DASHBOARD.DEFAULT_CONTENT_TAB.key);
  const [activePeriod, setActivePeriod] = useState<string>('M');
  const [country, setCountry] = useState<string>('');
  const [periodRange, setPeriodRange] = useState<string>(periodsRange.lastQuarter);
  const [fromDate, setFromDate] = useState<Date | null>(null);
  const [toDate, setToDate] = useState<Date | null>(null);
  const [locations, setLocations] = useState<Array<any>>([]);
  const [orgStructures, setOrgStructures] = useState<Array<any>>([]);
  const [departments, setDepartments] = useState<Array<any>>([]);
  const [noPermissions, setNoPermissions] = useState<boolean>(false);
  
  const [filters, setFilters] = useState<any>({});

  const tabs: any = HR_DASHBOARD_CONTENT_TABS;
  
  useEffect(() => {
    if (Object.keys(filters).length === 0) return;
    store.dispatch(setOverlayIsVisible(true));
    store.dispatch(loaderActions.showLoader());
    HRDashboardClient.getTurnoverData(filters)
      .then((data: any) => {
        setLocations([data.overview, ...data.locations]);
        setOrgStructures(data.org_structures);
        setDepartments(data.departments);
      })
      .catch((error: any) => {
        console.log(error);
        if (error.status === 403) {
          setNoPermissions(true);
          toastr.error('', 'No permissions to view this data');
        } else {
          toastr.error('', 'This data is unavailable');
        }
      })
      .finally(() => {
        store.dispatch(setOverlayIsVisible(false));
        store.dispatch(loaderActions.hideLoader());
      });
  }, [filters]);

  const exportCSV = (): void => {
    HRDashboardClient.getTurnoverCSV(filters)
      .then((data: any) => {
        if (data) {
          const file = new Blob([data], { type: 'text/csv' });
  
          let fromDateFile = fromDate, toDateFile = toDate;
          if (!fromDateFile || !toDateFile) {
            const today = new Date(), quarter: number = Math.floor((today.getMonth() / 3));
            fromDateFile = new Date(today.getFullYear(), quarter * 3 - 3, 1);
            toDateFile = new Date(fromDateFile.getFullYear(), fromDateFile.getMonth() + 3, 0);
          }
  
          const from = DateHelper.getFormatted2Date(fromDateFile);
          const to = DateHelper.getFormatted2Date(toDateFile);
  
          FileSaver.saveAs(file, `Star-turnover-${from}~${to}.csv`);
          HRDashboardClient.http.unsetResponseType();
        } else {
          toastr.error('', 'Empty data');
        }
      })
      .catch((error: any) => {
        toastr.error('', 'This data is unavailable');
      });
  };

  useEffect(() => {
    const params: any = {
      period_range: periodRange,
      period_type: activePeriod,
      tab: activeTab
    };
    if (periodRange === periodsRange.custom) {
      if (fromDate) {
        Object.assign(params, { date_from: DateHelper.getFormatted2Date(fromDate) });
      } else {
        Object.assign(params, { date_from: DateHelper.getFormatted2Date(new Date('2008-04-01')) });
      }
    
      if (toDate) {
        Object.assign(params, { date_to: DateHelper.getFormatted2Date(toDate) });
      } else {
        Object.assign(params, { date_to: DateHelper.getFormatted2Date(new Date()) });
      }
    }
    if (country) {
      Object.assign(params, { location: country });
    }
    if (JSON.stringify(params) !== JSON.stringify(filters)) {
      setFilters(params);
    }
  }, [periodRange, activePeriod, country, fromDate, toDate]);

  const defineContainerHeight = () => {
    const documentElementHeight: number = window.document.documentElement.clientHeight;
    const headerElement = document.getElementsByClassName('header-wrapper')[0] as HTMLElement;
    if (documentElementHeight && headerElement) {
      return `${documentElementHeight - headerElement.clientHeight - 30}px`;
    } else {
      return 0;
    }
  }

  return (
    <section className="h-100" style={{"background": "#e9f0f3"}}>
      {
        noPermissions
          ? <NoPermissions/>
          : (
            <section>
              <br/>
              <div className="data-wrapper" style={{height: defineContainerHeight()}}>
                <div className="sticky-wrapper">
                  <div className="details-control">
                    <div className="row">
                      <div className="col-auto mr-auto">
                        <ul className="tabs">
                          {
                            Object.keys(tabs).map((key: string, i: number) => {
                              return (
                                <li
                                  className={ `tab${tabs[key].key === activeTab ? ' active' : ''}` }
                                  key={ i }
                                  onClick={ () => setActiveTab(tabs[key].key) }>
                                  { tabs[key].label }
                                </li>
                              )
                            })
                          }
                        </ul>
                      </div>
                      <div className="export-container">
                        <div className="export-wrapper" onClick={ exportCSV }>
                          <DownloadIcon/> Export to CSV
                        </div>
                        <div className="turnover-markers-wrapper">
                          <span className="marker good">good</span>
                          <span className="marker bad">bad</span>
                        </div>
                      </div>
                    </div>
                  </div>
                  <TurnoverControl
                    activeTab={ activeTab }
                    activePeriod={ activePeriod }
                    country={ country }
                    setActivePeriod={ setActivePeriod }
                    setCountry={ setCountry }
                    setPeriodRange={ setPeriodRange }
                    setFromDate={ setFromDate }
                    setToDate={ setToDate }
                  />
                </div>
                <TurnoverDetails
                  activeTab={ activeTab }
                  locations={ locations }
                  orgStructures={ orgStructures }
                  departments={ departments }
                />
              </div>
            </section>
          )
      }
    </section>
  );
}
