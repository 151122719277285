import React, { FunctionComponent } from 'react';
import { EmployeeTableWrapper } from '../components/EmployeeList/EmployeeTable/EmployeeTableWrapper';

export const EmployeeListPage: FunctionComponent = () => {
  return (
    <>
      <EmployeeTableWrapper />
    </>
  );
}
