// core
import React, { useEffect, useState } from 'react';
import { Redirect, Route, useLocation } from 'react-router-dom';
import { useDispatch, useSelector, useStore } from 'react-redux';

// store
import { GlobalState } from '../store/types';
import { AUTHORIZATION_LOGGED_IN_STATE } from '../store/reducers';
import { setPermissionForElementsThunk } from '../store/permission/actions';

// pages
import { EmployeeListPage } from './EmployeeListPage';
import { WRPage } from './WRPage';
import { AddEmployeePage } from './AddEmployeePage';
import { StructurePage } from './StructurePage';
import { MyTeamPage } from "./MyTeamPage";
import { MyPage } from './MyPage';
import { HRDashboard } from './HRDashboard';
import { Report } from './Report';

import CurrentUserService from '../services/user/CurrentUserService';
import PermissionStaffService from '../services/security/PermissionStaffService';

// components
import { Header } from '../components/Header/Header';
import { Overlay } from '../components/Overlay/Overlay';
import { Spinner } from "../components/Spinner/Spinner";

import { HR_DASHBOARD } from '../constants';

export const AuthorizedPages: React.FC = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  const myStaff = useSelector((state: GlobalState) => state.staff.myStaff)
  const { getState, subscribe } = useStore();
  const [isLoggedIn, setIsLoggedIn] = useState<boolean>(getState()[AUTHORIZATION_LOGGED_IN_STATE].isLoggedIn);
  const [redirect, setRedirect] = useState<string>('')

  useEffect(() => {
    return subscribe(() => {
      setIsLoggedIn(getState()[AUTHORIZATION_LOGGED_IN_STATE].isLoggedIn)
    });
  }, [isLoggedIn, getState, subscribe]);

  useEffect(() => {
    if (isLoggedIn) {
      CurrentUserService.fetchCurrentUser();
      dispatch(setPermissionForElementsThunk());
    }
  }, [isLoggedIn]);

  useEffect(() => {
    if (myStaff) {
      PermissionStaffService.setUser(myStaff);
      const showMyTeam = PermissionStaffService.hasRole(['ROLE_LM']);
      setRedirect(showMyTeam ? '/my-team' : '/my');
    }
  }, [isLoggedIn, myStaff]);

  if (isLoggedIn) {
    return (
      <div className="application">
        <Header/>
        <main className="custom-container">
          <Route exact path="/">
            { redirect && <Redirect to={ redirect } /> }
          </Route>
          <Route path="/employee/list" component={ EmployeeListPage } />
          <Route path="/my-team" component={ MyTeamPage } />
          <Route path="/staff/:staffId/work-records/:wrId?" component={ WRPage } />
          <Route path="/add-employee" component={ AddEmployeePage } />
          <Route path="/structure" component={ StructurePage } />
          <Route path="/my" component={ MyPage } />
          <Route path={ HR_DASHBOARD.HR_DASHBOARD_BASE_URL } component={ HRDashboard } />
          <Route path="/report/:reportId" component={ Report } />
        </main>
        <Overlay />
        <Spinner />
      </div>
    );
  }

  return <Redirect to={ { pathname: '/login', state: { from: location } } }/>;
}
