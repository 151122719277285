import React, { useEffect, useState } from 'react';
import { ErrorMessage, Form, Formik } from 'formik';

import store from '../../../../store/store';
import { setShouldUpdate } from '../../../../store/staff/actions';
import { dispatchPopupState } from '../../../../store/popup/types';

import { Staff } from '../../../../interfaces/Staff';
import { DateField } from '../../../Forms/DateField';
import { PopupCloser } from '../../../Popup/PopupCloser';

import ProfileState, { initialState as fieldsProfile, ProfileStateInterface } from './ProfileState';
import { ProfileFormFields } from './ProfileFormFields';
import { schema } from './ProfileFormSchema';

import staffHelper from '../../../../services/data/StaffHelper';

import { ATTRIBUTES_MAP, ATTRIBUTE_NAMES } from '../../../../constants';

import './profile-form.scss';

const labels = ATTRIBUTE_NAMES['profile'];
const attributes = ATTRIBUTES_MAP.staff;

interface ProfileFormProps {
  staff: Staff;
  popupName: string;
}

export const ProfileForm: React.FC<ProfileFormProps> = (props: ProfileFormProps) => {
  const { staff, popupName } = { ...props };
  const [initialState, setInitialState] = useState<ProfileStateInterface | null>(null);

  const submitFunction = (values: object, setFieldError: Function, setFieldTouched: Function) => {
    Object.keys(fieldsProfile).forEach((key) => {
      setFieldTouched(key, true);
    });
    staffHelper.update(staff, values)
      .then((result: any) => {
        store.dispatch(setShouldUpdate(true));
        dispatchPopupState(popupName, false);
      })
      .catch((error: any) => {
        let data = error.data;
        if (data.required) {
          Object.keys(data.required).forEach((key: string) => {
            setFieldError(attributes[key], data.required[key]);
          });
        }
        if (data.username) {
          setFieldError('username', data.username);
        }
      });
  }

  useEffect(() => {
    if (initialState == null) {
      setInitialState(ProfileState.generateFromStaff(staff));
    }
  }, [initialState]);

  if (initialState) {
    return (
      <Formik
        validateOnBlur={ true }
        initialValues={ initialState }
        validationSchema={ schema }
        onSubmit={(values, { setFieldError, setFieldTouched }) => {
          submitFunction(values, setFieldError, setFieldTouched);
        }}>
        {({
            errors,
            touched,
            values,
            setFieldValue,
            validateForm,
            submitForm
        }) => (
          <Form className="edb-form edit-profile-form">
            <div className="form-fields">
              <ProfileFormFields
                values={ values }
                staff={ staff }
                mode={ "MODE_STAFF_EDIT" }
                formErrors={ [] }
                errors={ errors }
                touched={ touched }
                setFieldValue={ setFieldValue }
              />
            </div>
            <div className="form-footer">
              <div className="row">
                <div className="col-3">
                  <div className="form-group">
                    <label htmlFor="applyChanges">{labels['applyChanges']}</label>
                    <DateField
                      autoComplete='none'
                      id="applyChanges"
                      name="applyChanges"
                      className="form-control"
                      popperPlacement="top-start"
                      onChange={ () => {} }
                    />
                    <ErrorMessage name="applyChanges"/>
                  </div>
                </div>
                <div className="col-6 offset-3 form-buttons">
                  <PopupCloser popupName={ popupName }>
                    <button className="edb-btn edb-btn--secondary" type="reset">
                      Cancel
                    </button>
                  </PopupCloser>
                  <button
                    type="button"
                    className="edb-btn edb-btn--primary"
                    onClick={() => validateForm().then((errors) => {
                      const keys = Object.keys(errors);
                      if (keys.length > 0) {
                        const errorElement = document.querySelector(`[id="${keys[0]}"]`) as HTMLElement
                          || document.querySelector(`[id="${keys[0]}Wrapper"]`) as HTMLElement;
                        if (errorElement) errorElement.focus();
                      }
                      return submitForm();
                    })}
                  >
                    Save
                  </button>
                </div>
              </div>
            </div>
          </Form>
        )}
      </Formik>
    );
  }
  return <></>;
}
