import React, { useEffect, useState } from 'react';
import { ErrorMessage, Form, Formik } from 'formik';

import store from '../../../../store/store';
import { dispatchPopupState } from '../../../../store/popup/types';
import { setShouldUpdate as setShouldUpdateStaff } from '../../../../store/staff/actions';
import { setShouldUpdate as setShouldUpdateWR } from '../../../../store/workRecord/actions';

import wrHelper from '../../../../services/data/WorkRecordHelper';
import { WorkRecord } from '../../../../interfaces/WorkRecord';

import { DateField } from '../../../Forms/DateField';
import { Status } from '../../Common/Status';
import { PopupCloser } from '../../../Popup/PopupCloser';
import ActivateWorkRecordState, { ActivateWorkRecordStateInterface } from './ActivateWorkRecordState';

import { schema } from './ActivateWorkRecordFormSchema';

import { ATTRIBUTE_NAMES } from '../../../../constants';

import './activate-work-record-form.scss';

const labels = ATTRIBUTE_NAMES['activateWorkRecord'];

interface ActivateWorkRecordFormProps {
  popupName: string
  workRecord: WorkRecord
}

export const ActivateWorkRecordForm: React.FC<ActivateWorkRecordFormProps> =
  (props: ActivateWorkRecordFormProps) => {
    const {workRecord, popupName} = {...props};
    const [initialState, setInitialState] = useState<ActivateWorkRecordStateInterface | null>(null);

    const submission = (values: ActivateWorkRecordStateInterface) => {
      wrHelper.activate(workRecord, values).then((data: any) => {
        store.dispatch(setShouldUpdateWR(true));
        store.dispatch(setShouldUpdateStaff(true));
        dispatchPopupState(popupName, false);
      });
    }

    useEffect(() => {
      if (initialState == null) {
        setInitialState(ActivateWorkRecordState.generateFromWorkRecord(props.workRecord));
      }
    }, [])

    if (initialState) {
      return (
        <Formik
          initialValues={initialState}
          validationSchema={schema}
          onSubmit={values => {
            submission(values)
          }}>
          {({errors, touched, values}) => (
            <Form className="edb-form">
              <div className="form-activate-wr form-activate-wr-employ-mode">
                <div className="info">
                  Please check if Start Date is correct and click Employ to activate this Work Record.
                </div>
                <div className="edb-employ-form-wrapper">
                  <div className="form-activate-wr-header">
                    <div className="clearfix">
                      <div className="main-attribute float-left">
                        {workRecord.position.value}
                      </div>
                      <div className="status float-right">
                        <Status value={workRecord.status} isWR={true}/>
                      </div>
                    </div>
                    <div className="secondary-attribute">
                      <span>{workRecord.employment.value}</span>
                      <span>{`${workRecord.location.value}, ${workRecord.location.country}`}</span>
                    </div>
                  </div>
                  <div className="form-activate-wr-body">
                    <div className="row">
                      <div className="col-6">
                        <div className="form-group">
                          <label htmlFor="startDate">{labels['startDate']}</label>
                          <DateField
                            autoComplete='off'
                            id="startDate"
                            name="startDate"
                            className={`form-control ${errors.startDate && touched.startDate ? 'invalid-field' : ''}`}
                            onChange={() => {
                            }}
                          />
                        </div>
                      </div>
                      <div className="col-6">
                        <div className="form-group">
                          <label htmlFor="endDate">{labels['endDate']}</label>
                          <DateField
                            autoComplete='off'
                            disabled={true}
                            id="endDate"
                            name="endDate"
                            className="form-control"
                            onChange={() => {
                            }}/>
                          <ErrorMessage name="endDate"/>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="edb-layoff-empl__footer">
                <div className="edb-layoff-empl__button edb-pull-right">
                  <button className="edb-btn edb-btn--primary" type="submit">
                    Employ
                  </button>
                </div>
                <div className="edb-layoff-empl__button edb-pull-right edb-margin-right">
                  <PopupCloser popupName={popupName}>
                    <button className="edb-btn edb-btn--secondary" type="reset">
                      Cancel
                    </button>
                  </PopupCloser>
                </div>
              </div>
            </Form>
          )}
        </Formik>
      );
    }
    return <></>;
  }
