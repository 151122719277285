import {Staff as StaffInterface} from '../../interfaces/Staff';
import diffService from './DiffService';
import requestService from './RequestService';
import DateHelper from '../../utils/date/DateHelper';
import client, {STAFF} from '../http/RequestClient';
import staffClient from '../http/StaffClient';
import mediaClient from '../http/MediaClient';
import EditProfileForm from '../../models/forms/EditProfileForm';

class StaffHelper {

  public validate(staff: StaffInterface, formValues: any) {
    const body = this.prepareStaffValidationBody(staff, formValues);
    return staffClient.validate(body);
  }

  public update(staff: StaffInterface, formValues: any) {
    const promisses: Array<any> = [];
    const body = this.prepareStaffBody(staff, formValues);
    promisses.push(client.update(staff.id, STAFF, body));
    const mediaBody = this.prepareMediaBody(staff, formValues);
    if (mediaBody) {
      promisses.push(mediaClient.addAttachment(mediaBody));
    }
    return Promise.all(promisses);
  }

  public saveStaffMedia(staff: StaffInterface, formValues: any): Promise<any> | null {
    const mediaBody = this.prepareMediaBody(staff, formValues);
    return mediaBody ? mediaClient.addAttachment(mediaBody) : null;
  }

  private prepareStaffValidationBody(staff: StaffInterface, formValues: any) {
    let values = formValues as EditProfileForm;
    const applyChanges = DateHelper.getFormattedDate(values.applyChanges);
    this.preparePhoneField(values);
    let diff = diffService.getAttributesDiff(staff.attributes, values, 'staff', true);
    diff.forEach((value: any) => {
      value.apply_from = applyChanges;
    });
    diff = requestService.upgradeForUpdate(diff);
    let body: any = {
      attributes: diff
    }
    return this.finalizeBodyNew(staff, formValues, body);
  }

  public prepareNewStaffBody(staff: StaffInterface, formValues: any) {
    let values = formValues as EditProfileForm;
    let diff = diffService.getAttributesDiff(staff.attributes, values, 'staff', true);
    diff = requestService.upgradeForUpdate(diff);
    let body: any = {
      attributes: diff
    };

    const staffDiff = diffService.getStaffPropertyDiff(staff, formValues);
    Object.keys(staffDiff).forEach((key) => {
      body[key] = staffDiff[key];
    });

    return body;
  }

  public prepareStaffBody(staff: StaffInterface, formValues: any) {
    let values = formValues as EditProfileForm;
    const applyChanges = DateHelper.getFormattedDate(values.applyChanges);
    this.preparePhoneField(values);
    let diff = diffService.getAttributesDiff(staff.attributes, values, 'staff');
    diff.forEach((value: any) => {
      value.apply_from = applyChanges;
    });
    diff = requestService.upgradeForUpdate(diff);

    let body: any = { attributes: diff };

    const staffDiff = diffService.getStaffPropertyDiff(staff, formValues);
    Object.keys(staffDiff).forEach((key) => {
      body[key] = staffDiff[key];
    });

    return body;
  }

  private prepareMediaBody(staff: StaffInterface, formValues: any): object | null {
    if (formValues.photo && formValues.photo !== staff.avatar) {
      return {
        attachment_type: 1,
        data: formValues.photo,
        id: staff.id
      }
    }
    return null;
  }

  private preparePhoneField(formValues: any) {
    const phoneFields = ['phone1', 'phone2', 'emergContactPhone'];

    for (let key in formValues) {
      if (phoneFields.includes(key)) {
        if (formValues[key].length && formValues[key][0] !== '+') {
          formValues[key] = `+${formValues[key]}`;
        }
      }
    }
  }

  private finalizeBodyNew(staff: StaffInterface, formValues: any, body: any) {
    const staffProperties = this.buildStaffProperty(staff, formValues);
    Object.keys(staffProperties).forEach((key) => {
      body[key] = staffProperties[key];
    });

    return body;
  }

  private buildStaffProperty(staff: StaffInterface, formValues: any) {
    let property: any = {};
    if (formValues.companyStartDate) {
      property['company_start_date'] = DateHelper.getFormatted2Date(formValues.companyStartDate);
    }
    if (formValues.username) {
      property['username'] = formValues.username;
    }
    if (formValues.alumni) {
      property['alumni'] = formValues.alumni;
    }
    return property;
  }
}

export default new StaffHelper();
