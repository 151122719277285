import React, { useEffect, useState } from 'react';

import store from '../../../../store/store';
import { setStaff } from '../../../../store/staff/actions';

import client from './../../../../services/http/StaffClient';
import StaffMapper from '../../../../mappers/response/StaffMapper';

import { WorkRecordListItem } from './WorkRecordListItem/WorkRecordListItem';
import { UnitTreePreviewBreadcrumbs } from './UnitTreePreviewBreadcrumbs/UnitTreePreviewBreadcrumbs';

import iconClose from './../../../../assets/images/org-tree/icon-close.svg';

import './unit-tree-preview.scss';

interface UnitTreePreviewProps {
  selectedUnit: any;
  showUnitPreview: boolean;
  setShowUnitPreview: Function;
  setSelectedWRId: Function;
}

const TYPE_COMPANY = 'Company';

export const UnitTreePreview: React.FC<UnitTreePreviewProps> = (props: UnitTreePreviewProps) => {
  const {selectedUnit, showUnitPreview, setShowUnitPreview, setSelectedWRId} = {...props};

  let breadCrumbs: Array<string> = [];
  let workRecordListItem;
  let avatarManager: string = '';

  const [height, setHeight] = useState<string>('100%');

  useEffect(() => {
    const element = document.getElementsByClassName('unit-tree-preview')[0] as HTMLElement;
    const blockMenu = document.getElementsByClassName('unit-tree-preview--menu')[0] as HTMLElement;
    const blockHeader = document.getElementsByClassName('unit-tree-preview--header')[0] as HTMLElement;
    const handleResize = () => {
      if (element) {
        setHeight(`${element.clientHeight - blockMenu.clientHeight - blockHeader.clientHeight - 20}px`);
      }
    };
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  });

  const showPreviewWR = (event: React.MouseEvent<HTMLLIElement>): void => {
    const idWr = event.currentTarget.getAttribute('data-wr-id');
    const idStaff = event.currentTarget.getAttribute('data-staff-id');

    if (idStaff) {
      client.get(parseInt(idStaff)).then((data: any) => {
        const staff = StaffMapper.creatStaffFromStaffResponse(data);
        store.dispatch(setStaff(staff));
        if (idWr) {
          setSelectedWRId(idWr);
        } else if (staff.activeWRs.length) {
          setSelectedWRId(staff.activeWRs[staff.activeWRs.length - 1].id);
        } else {
          setSelectedWRId(staff.inactiveWRs[0].id);
        }
      });
    }
  };

  function closeBlock() {
    setShowUnitPreview(false);
  }

  if (Object.keys(selectedUnit).length) {
    getBreadCrumbs(selectedUnit);

    const workRecordList = getWorkRecordList(selectedUnit);
    const workRecordListSorted = naturalSort(workRecordList, 'lastName');

    workRecordListItem = workRecordListSorted.map((item: any) => {
      let avatar = getUserAvatar(item);
      return <WorkRecordListItem key={ `wr-${item.id}` } workRecord={ item } avatar={ avatar } showPreviewWR={ showPreviewWR } />
    });

    avatarManager = getManagerAvatar(selectedUnit);
  }

  function getBreadCrumbs(data: any) {

    if (data.type === TYPE_COMPANY) {
      return;
    }

    breadCrumbs.push(data.full_name ? data.full_name : data.name);

    if (data.parent) {
      getBreadCrumbs(data.parent);
    }
  }

  function getWorkRecordList(unit: any) {
    let list: any = [];
    if (unit.work_records) {
      Object.keys(unit.work_records).forEach((key) => {
        list.push(unit.work_records[key]);
      });
    }

    let children = unit.children ? unit.children : unit._children ? unit._children : [];

    if (children.length) {
      children.forEach((item: any) => {
        Array.prototype.push.apply(list, getWorkRecordList(item));
      });
    }

    return list;
  }

  function naturalSort(list: any, field: string) {
    return list.sort(function(a: any, b: any) {
      return a[field].localeCompare(b[field], undefined, {
        numeric: true,
        sensitivity: 'base'
      });
    });
  }

  function getAvatarByUser(idStaff: number) {
    return `/api/v1/media/avatar/${idStaff}?style=large`;
  }

  function getManagerAvatar(unit: any) {
    return unit.manager_avatar ? unit.manager_avatar : getAvatarByUser(unit.manager_id);
  }

  function getUserAvatar (unit: any) {
    return unit.avatar ? unit.avatar : getAvatarByUser(unit.staffId);
  }

  if (Object.keys(selectedUnit).length) {
    return (
      <div className={`unit-tree-preview ${showUnitPreview ? "active" : ""}`}>
        <div className="unit-tree-preview--container">
          <div className="row unit-tree-preview--menu">
            <div className="col position-relative">
              <img alt="icon-close" src={`${iconClose}`} className="position-absolute img-close" onClick={closeBlock}/>
              <UnitTreePreviewBreadcrumbs breadCrumbs={breadCrumbs}/>
            </div>
          </div>
          <div className="row border-bottom unit-tree-preview--header">
            <div className="col">
              <ul className="list-unstyled d-flex row">
                <li
                  className="col-custom m-0"
                  data-staff-id={ selectedUnit.manager_id }
                  onClick={ showPreviewWR }
                >
                  <div className="media">
                    <div className="rounded-circle overflow-hidden media-image" style={{backgroundImage: `url(${avatarManager})`}}></div>
                    <div className="media-body">
                      <h5><strong>{ selectedUnit.manager_first_name } { selectedUnit.manager_last_name }</strong></h5>
                      <span className="text-muted">{ selectedUnit.manager_position }</span>
                    </div>
                  </div>
                </li>
                <li className="col-custom m-0 p-0 indicators">
                  <span className="d-block inline-custom text-battleship-grey">
                    Headcount
                    <strong className="text-charcoal-grey">{ selectedUnit.total_count }</strong>
                  </span>
                  <span className="d-block inline-custom inline-custom-lm text-battleship-grey">
                    FTE
                    <strong className="text-charcoal-grey">{ selectedUnit.sum_of_max_total_loads }</strong>
                  </span>
                </li>
              </ul>
            </div>
          </div>
          <div className="unit-tree-preview--body" style={{ height: height, position: 'absolute' }}>
            <div>
              <div className="row" style={{ padding: '30px 0' }}>
                <div className="col">
                  <ul className="list-unstyled d-flex row">{ workRecordListItem }</ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }

  return <></>;

};