import {CVStatusType} from '../store/cv/types';

export const ENDPOINTS = {
  SECURITY_LOGIN_CHECK: '/api/v1/auth/login_check',
  SECURITY_LOGOUT: '/api/v1/auth/logout',
  TOKEN_VALIDATE: '/api/v1/oauth/token-validate',
  PERMISSION_ELEMENTS: '/api/v1/permission/elements/all',

  ATTRIBUTES_ALL: '/api/v1/attributes/all',
  ATTRIBUTES_ALL_DEFAULT: '/api/v1/attributes/all/default',

  SUBORDINATES_ATTRIBUTES: '/api/v1/attributes/subordinates',
  SUBORDINATES_ATTRIBUTES_DEFAULT: '/api/v1/attributes/subordinates/default',
  DIRECT_ATTRIBUTES: '/api/v1/attributes/direct',
  DIRECT_ATTRIBUTES_DEFAULT: '/api/v1/attributes/direct/default',
  MY_SUBORDINATES: '/api/v1/work-record/list/mysubordinates',
  MY_DIRECT_TEAM: '/api/v1/work-record/list/myteam',

  DICTIONARY_ALL: '/api/v1/dictionary/all',
  DICTIONARY_STAFF_ALL: '/api/v1/staff/all',
  DICTIONARY_USERS: '/api/v1/ldap/users',
  DICTIONARY_REPORT_FILTERS: '/api/v1/dictionary/report-filters',
  DICTIONARY_COUNTRY_LIST: '/api/v1/dictionary/country-list',
  DICTIONARY_CITY_LIST: (name: string) => `/api/v1/dictionary/city-list?country_name=${name}`,

  STRUCTURE_UNIT_TREE: '/api/v1/unit/structure',
  STRUCTURE_LM_TREE: '/api/v1/staff/structure',
  STRUCTURE_CM_TREE: '/api/v1/staff/cm/tree',

  DETAILS: (id: string) => `/api/v1/details/${id}`,
  DETAILS_CSV: (id: string) => `/api/v1/details/${id}/csv`,
  DETAILS_CONFIG: '/api/v1/details-config',

  DOCUMENT_ITEMS_STAFF: '/api/v1/compliance/records/staff-list',
  DOCUMENT_MY_LIST: '/api/v1/compliance/records/my-list',
  DOCUMENT_ITEMS_SIGN: (id: number) => `/api/v1/compliance/records/sign/${id}`,
  DOCUMENT_ITEMS_PDF_CONTENT: (id: number) => `/api/v1/compliance/documents/${id}/content`,

  COMPLIANCE_DOCUMENT_ALL: '/api/v1/compliance/documents/all',
  COMPLIANCE_STATUS_ALL: '/api/v1/compliance/records/statuses',
  COMPLIANCE_SUBORDINATES_LIST: (page: number, filter: string, sort: string) => `/api/v1/compliance/records/subordinates-list?page=${page}&filter=${filter}&sort=${sort}`,
  COMPLIANCE_ALL_LIST: (page: number, filter: string, sort: string) => `/api/v1/compliance/records/all?page=${page}&filter=${filter}&sort=${sort}`,
  COMPLIANCE_EXPORT_CSV: (filter: string, sort: string) => `/api/v1/compliance/records/export?filter=${filter}&sort=${sort}`,

  EDUCATION_DEGREES: '/api/v1/education/degrees',
  EDUCATION_SCHOOLS: '/api/v1/education/schools',
  EDUCATION_EDUCATION_ITEM_STAFF: '/api/v1/education/education-item/staff',
  EDUCATION_EDUCATION_ITEM: '/api/v1/education/education-item',

  FEEDBACK_WORK_RECORD: (id: number) => `/api/v1/work-record/${id}/feedback`,
  FEEDBACK_WORK_RECORD_CREATE: (id: number) => `/api/v1/work-record/${id}/feedback/create`,
  FEEDBACK_WORK_RECORD_UPDATE: (id: number, feedbackId: number) => `/api/v1/work-record/${id}/feedback/update/${feedbackId}`,

  CV_STATUS: (id: number, status: string) => `/api/v1/cv/${id}/${status}`,
  CV_CONFIG: '/api/v1/cv/config',
  CV_CREATE: (id: number) => `/api/v1/cv/${id}`,
  CV_UPDATE: (id: number) => `/api/v1/cv/${id}`,
  CV_APPROVE: (id: number) => `/api/v1/cv/${id}/approve`,
  CV_CLONE: (wrId: number, attributeId: number) => `/api/v1/cv/${wrId}/clone/${attributeId}`,
  CV_REQUEST: (id: number) => `/api/v1/cv/request-cv/${id}`,
  CV_REQUEST_UPDATE: (id: number) => `/api/v1/cv/request-cv-update/${id}`,
  CV_REQUEST_APPROVAL: (id: number) => `/api/v1/cv/request-cv-approval/${id}`,
  CV_CHECK_APPROVED: (id: number) => `/api/v1/cv/is-approved/${id}`,
  CV_CHECK_PENDING: (id: number) => `/api/v1/cv/is-pending/${id}`,

  CV_GET_PDF: (id: number, status: CVStatusType) => `/api/v1/cv/${id}/pdf/${status}`,
  CV_GET_DOCX: (id: number, status: CVStatusType) => `/api/v1/cv/${id}/docx/${status}`,
  CV_GET_LIST: (staffId: number) => `/api/v1/cv/list/${staffId}`,

  PROJECT_ALL: '/api/v1/project/all',

  COUNTRIES: '/api/v1/dictionary/country',

  REASONS_TO_LEAVE: '/api/v1/dictionary/reason-to-leave',

  MEDIA: '/api/v1/media',

  STAFF_MY: '/api/v1/staff/my',
  STAFF_GET: '/api/v1/staff',
  STAFF_LM_LIST: '/api/v1/staff/list?type=line_manager',
  STAFF_PP_LIST: '/api/v1/staff/list?type=people_partner',
  STAFF_VALIDATE: '/api/v1/staff/validate',
  STAFF_SEARCH: (q: string) => `/api/v1/staff/all?name=${q}`,
  STAFF_PARENT_LM_LIST: (staffId: number, wrId: number) => `/api/v1/staff/${staffId}/work-record/${wrId}/parent-lm-list`,
  STAFF_GET_LM_TREE_CSV: (date: string) => `/api/v1/staff/exportlmtree?date=${date}`,
  WORK_RECORD_CLOSE_INFO: (staffId: number) => `/api/v1/staff/validate-close-work-record/${staffId}`,

  WORK_RECORD_EMPLOYEE_LIST: '/api/v1/work-record/list/all',
  WORK_RECORD_LIST: '/api/v1/work-record/all',
  WORK_RECORD_GET: '/api/v1/work-record',
  WORK_RECORD_VALIDATE: '/api/v1/work-record/validate',
  WORK_RECORD_SAVE: '/api/v1/work-record/save',
  WORK_RECORD_ACTIVATE: (id: number) => `/api/v1/work-record/${id}/set-active`,
  WORK_RECORD_CLOSE: (id: number) => `/api/v1/work-record/${id}/set-layoff`,
  WORK_RECORD_SKILL_TREE: (id: number) => `/api/v1/work-record/skill-tree/${id}`,
  WORK_RECORD_STAFF_BY_PROJECT: (code: string) => `/api/v1/work-record/staff-id?code=${code}`,
  WORK_RECORD_IS_CM: (staffId: number) => `/api/v1/work-record/iscm/${staffId}`,
  WORK_RECORD_GET_CV: (id: number) => `/api/v1/cv/${id}/pending`,

  REQUEST_CREATE: '/api/v1/request/create',

  HR_DASHBOARD_HC_SNAPSHOT_EXPORT_CSV: (queryString: string) => `/api/v1/reporting/headcount/csv?${queryString}`,
  HR_DASHBOARD_HC_SNAPSHOT_EXPORT_NAVISION: (queryString: string) => `/api/v1/reporting/headcount/navision/download?${queryString}`,
  HR_DASHBOARD_HC_SNAPSHOT_GET_HEADCOUNT_DATA: (queryString: string) => `/api/v1/reporting/headcount?${queryString}`,
  HR_DASHBOARD_HEADCOUNT_DATA: (query: string) => `/api/v1/reporting/headcount-dynamic?${query}`,
  HR_DASHBOARD_TURNOVER_DATA: (query: string) => `/api/v1/reporting/turnover?${query}`,
  HR_DASHBOARD_TURNOVER_EXPORT_CSV: (query: string) => `/api/v1/reporting/turnover/csv?${query}`,
  HR_DASHBOARD_TENURE_EXPORT_CSV: (filterString: string) => `/api/v1/statistic/tenure/export?filter=${filterString}`,
  HR_DASHBOARD_TENURE_DATA: (
    page: number,
    pageSize: number,
    filterString: string
  ) => `/api/v1/statistic/tenure/list?page=${page}&pageSize=${pageSize}&filter=${filterString}`,

  HR_DASHBOARD_LEAVERS_DATA: (query: string) => `/api/v1/reporting/leave?${query}`,
};
