import React, { useEffect, useMemo, useState } from 'react';

import './checkbox-column.scss';

interface CheckboxColumnFilter {
  column: {
    filterValue: string;
    preFilteredRows: Array<any>;
    setFilter: Function;
    id: string;
  };
}

export const CheckboxColumnFilter: React.FC<CheckboxColumnFilter> = (props: CheckboxColumnFilter) => {
  const column = props.column;
  const { filterValue, preFilteredRows, setFilter, id } = { ...column };
  const checked: any = (filterValue) ? filterValue : [];
  const [searchValue, setSearchValue] = useState<string>('');
  const options = useMemo(() => {
    const options = new Set();
    preFilteredRows.forEach(row => {
      if (row.values[id]) {
        if (Array.isArray(row.values[id])) {
          row.values[id].forEach((value: string) => {
            options.add(value);
          })
        } else {
          options.add(row.values[id]);
        }
      }
    });
    //@ts-ignore
    return [...options.values()].sort().filter(row => {
      if (typeof row !== 'string') {
        row = String(row);
      }
      return row.toLowerCase().indexOf(searchValue.toLowerCase()) > -1;
    });
  }, [id, preFilteredRows, searchValue]);

  const [isCheckedAll, setIsCheckedAll] = useState<boolean>(false);

  useEffect(() => {
    if (!filterValue) {
      setIsCheckedAll(false);
    }
  }, [filterValue]);

  const onCheckedAll = () => {
    if (options?.length) {
      const currentFilter = filterValue || [] as Array<string>;
      options.forEach(row => {
        if (isCheckedAll) {
          // @ts-ignore
          currentFilter.splice(currentFilter.indexOf(row), 1);
        } else if (!currentFilter.includes(row)) {
          // @ts-ignore
          currentFilter.push(row);
        }
        setFilter(currentFilter);
      });
      setIsCheckedAll(!isCheckedAll);
    }
  };

  return (
    <div className="checkbox-filter-wrapper">
      <input
        type="text"
        className="input-filter-input"
        onChange={ (e) => setSearchValue(e.currentTarget.value || '') }
        placeholder="Start typing"
      />
      <span
        className="checkbox-filter-select-all"
        onClick={ onCheckedAll }>
        { !isCheckedAll ? 'Select All' : 'Deselect All' }
      </span>
      <div className="checkbox-list">
        {options.map((option, i: number) => (
            <div className="checkbox-wrapper" key={i}>
              <label className="checkbox-filter-label">
                <input
                  value={ option }
                  className="checkbox-filter-input"
                  type="checkbox"
                  checked={ (checked.indexOf(option) !== -1) }
                  onChange={event => {
                    const currentV = event.target.value || undefined;
                    if (currentV) {
                      const currentFV = filterValue || [] as Array<string>;
                      if (event.target.checked) {
                        //@ts-ignore
                        currentFV.push(currentV);
                      } else {
                        //@ts-ignore
                        currentFV.splice(currentFV.indexOf(currentV), 1);
                      }
                      setFilter(currentFV);
                    }
                  }}
                />
                { option }
              </label>
            </div>
          )
        )}
      </div>
    </div>
  )
}
